// All user-based actions.

export enum TemplateActionType {
    QUERY_TEMPLATE = 'QUERY_TEMPLATE',
    UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
    CLEAR_TEMPLATE = 'CLEAR_TEMPLATE',
    ADD_TOOLBAR_BLOCK = 'ADD_TOOLBAR_BLOCK',
    RENAME_EDITING_SURFACE_COLUMN = 'RENAME_EDITING_SURFACE_COLUMN',
    REORDER_EDITING_SURFACE_COLUMN = 'REORDER_EDITING_SURFACE_COLUMN',
    ADD_EDITING_SURFACE_BLOCK = 'ADD_EDITING_SURFACE_BLOCK',
    UPDATE_EDITING_SURFACE_BLOCK = 'UPDATE_EDITING_SURFACE_BLOCK',
    UPDATE_TOOLBAR_BLOCK = 'UPDATE_TOOLBAR_BLOCK',
    DELETE_EDITING_SURFACE_BLOCK = 'DELETE_EDITING_SURFACE_BLOCK',
    DELETE_TOOLBAR_BLOCK = 'DELETE_TOOLBAR_BLOCK',
    ADD_SHEET = 'ADD_SHEET',
    UPDATE_SHEET = 'UPDATE_SHEET',
    DELETE_SHEET = 'DELETE_SHEET',
}

export enum ProjectTemplateActionType {
    FIND_TEMPLATE_IN_PROJECT_DASHBOARD = 'FIND_TEMPLATE_IN_PROJECT_DASHBOARD',
    ADD_TEMPLATE_TO_PROJECT_DASHBOARD = 'ADD_TEMPLATE_TO_PROJECT_DASHBOARD',
    UPDATE_TEMPLATE_IN_PROJECT_DASHBOARD = 'UPDATE_TEMPLATE_IN_PROJECT_DASHBOARD',
    DELETE_TEMPLATE_FROM_PROJECT_DASHBOARD = 'DELETE_TEMPLATE_FROM_PROJECT_DASHBOARD',
}
