export enum BuilderActionType {
    USER_FIND_PROJECT = 'USER_FIND_PROJECT',
    USER_ADD_PROJECT = 'USER_ADD_PROJECT',
    USER_DELETE_PROJECT = 'USER_DELETE_PROJECT',
    USER_UPDATE_PROJECT = 'USER_UPDATE_PROJECT',
    USER_ADD_PROJECT_MEMBER = 'USER_ADD_PROJECT_MEMBER',
    USER_DELETE_PROJECT_MEMBER = 'USER_DELETE_PROJECT_MEMBER',
    USER_ADD_TEMPLATE = 'USER_ADD_TEMPLATE',
    USER_DELETE_TEMPLATE = 'USER_DELETE_TEMPLATE',
    USER_UPDATE_TEMPLATE = 'USER_UPDATE_TEMPLATE',
}
